<template>
  <master-layout
    bgColor="white"
  >
    <ion-fab
      vertical="top"
      horizontal="start"
      slot="fixed"
      @click="$router.go(-1)"
    >
      <ion-fab-button class="backbutton" color="light">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-fab-button>
    </ion-fab>

    <ion-grid
      v-if="winery"
    >

      <ion-row class="winery-section">
        <ion-img
          v-if="winery && winery.Logo"
          :src="winery.Logo.url"
          class="wineryLogo"
        />
        <h1 class="wineryName">{{ winery.Name }}</h1>
      </ion-row>

      <ion-row
        class="winery-section rating"
      >
        <ion-item
          lines="none"
          class="realTransparent"
        >
          <RatingSummary
            :ratings="winery.Ratings"
          />
        </ion-item>
      </ion-row>

      <ion-row
        v-for="rating in winery.Ratings"
        :key="rating.id"
        class="winery-section"
      >
        <div class="stars-date">
          <Stars
            :numberStars="rating.Stars"
          />
          <p class="mediumText">
            <HungarianDate
            :originalDate="rating.createdAt"
            />
          </p>
        </div>
        <p
          v-if="rating.Comment"
        >
          {{ rating.Comment }}
        </p>
      </ion-row>

    </ion-grid>

  </master-layout>
</template>

<script>
import {
  IonGrid, IonRow,
  IonFab, IonFabButton, IonIcon, IonImg, IonItem
} from '@ionic/vue'
import { addIcons } from 'ionicons'
import {
  arrowBack
} from 'ionicons/icons'
import RatingSummary from '@/components/RatingSummary.vue'
import Stars from '@/components/Stars.vue'
import HungarianDate from '@/components/HungarianDate.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CheckinConfirm',
  components: {
    IonGrid, IonRow,
    IonFab, IonFabButton, IonIcon, IonImg, IonItem,
    RatingSummary, Stars, HungarianDate
  },
  data () {
    return {
      winery: null
    }
  },
  computed: {
    ...mapGetters('wineries', {
      wineryInMemory: 'getWineryInMemory'
    })
  },
  created() {
    addIcons({
      'arrow-back': arrowBack
    })
  },
  ionViewWillEnter () {
    this.winery = this.wineryInMemory
    window.scrollTo(0, 0)
    this.ratingsCustom(this.winery.Ratings)
  },
  ionViewWillLeave () {
    this.setWineryInMemory(null)
  },
  methods: {
    ...mapActions('wineries', {
      setWineryInMemory: 'setWineryInMemory'
    }),
    ratingsCustom (ratingsArray) {
      return ratingsArray.reverse()
    }
  }
}
</script>

<style scoped>
ion-grid{
  color: #333333;
  padding: 0px;
  clear: both;
}
ion-row{
  --margin: 0px;
  padding: 20px 20px;
}
ion-row.rating{
  padding: 0px;
}
h3{
  font-family: Alphabet;
  font-size: 16px !important;
  width: 100%;
}
.winery-section{
  border-bottom: 1px solid #cccccc;
}
.icon{
  display: inline-block;
  margin-right: 8px;
  position: relative;
  top: 7px;
}
.iconleft{
  float: left;
  margin-bottom: 10px;
}
h1.wineryName{
  width: 100%;
  margin-bottom: 0;
}
.wineryLogo{
  max-width: 40%;
  max-height: 70px;
  margin-top: calc(30px + env(safe-area-inset-top));
  margin-left: auto;
  margin-right: auto;
}
.backbutton{
  margin-top: calc(10px + env(safe-area-inset-top));
}
.stars-date{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
