<template>
  <div class="rating-container">

    <p class="number-stars">{{ averageStars }}/5</p>
    <div class="stars-super-container">
      <Stars
        :numberStars="averageStars"
        :pushedClass="'pushedLeft'"
      />
    </div>
    <p class="review-number">
     {{ ratings.length }} értékelés alapján
    </p>

  </div>
</template>

<script>
import Stars from '@/components/Stars.vue'

export default {
  name: 'RatingSummary',
  components: {
    Stars
  },
  data () {
    return {
      averageRating: null
    }
  },
  props: [
    'ratings'
  ],
  mounted () {
    var avg = 0
    for (let i in this.ratings) {
      avg += this.ratings[i].Stars
    }
    console.log(avg)
    this.averageRating = Math.round(avg/this.ratings.length*10)/10
  },
  computed: {
    averageStars () {
      var avg = 0
      for (let i in this.ratings) {
        if ( this.ratings[i].Stars ) {
          avg += this.ratings[i].Stars
        }
      }
      return Math.round(avg/this.ratings.length*10)/10
    }
  }
}
</script>

<style scoped>
.rating-container{
  width: 100vw ;
  padding: 15px 0;
}
.number-stars{
  font-size: 25px;
  margin: 0 0 7px 0;
  text-align: center;
  font-weight: bold;
}
.large-number{
  font-size: 25px;
  margin: 0 0 10px 0;
  text-align: center;
  font-weight: bold;
}
.stars-super-container{
  margin-left: calc(50vw - 16px); /*--padding-start for ion-item */
  display: inline-block;
}
.review-number{
  margin: 8px 0 0 0;
  text-align: center;
}
ion-item.realTransparent{
  --background: transparent !important;
}
</style>
